<template>
  <img alt="logo" src="./assets/dengshi_index.png" class="responsive-image" />
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  components: {
    // HelloWorld
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.responsive-image {
  width: 100%; /* 设置图片宽度为浏览器宽度 */
  height: auto; /* 高度自适应，保持图片比例 */
}
</style>
